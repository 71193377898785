import React from 'react'
import { styled } from 'styled-components'

const InputBox = ({ placeholder, type, required }) => {
    return (
        <Container data-AOS='fade' required={required}>
            <input type={type} placeholder={placeholder} required={required} />
        </Container>
    )
}

export default InputBox

const Container = styled.div`
   input{
        width: 80%;
        margin: 10px 10% ;
        height: 40px;
        padding: 10px 15px;
        border-radius: 50px;
        border: none;
        background-color: #ff5e00fd;
        font-size: 1rem;
        color: #222;
        transition: all.5s ;
        @media (max-width:426px){
        height: 35px;
        font-size: .7rem;
 
        }
        &::placeholder{
            color: #222222b8;
            font-size: 1rem;
            font-weight: 600;
             @media (max-width:426px){
             font-size: .8rem;
 
        }

        }
        &:focus{
            outline: 1px solid #ff5c00;
            background-color: transparent;
        }
        &:valid{
            outline: 1px solid #ff5c00;
            background-color: ${(props) => props.required === undefined ? '#ff5c00' : 'transparent'};

        }
        &:hover{
            outline: 1px solid #ff5c00;
            background-color: #ff5e005b;
        }

    } 
    
`;