import React from 'react'
import { styled } from 'styled-components'
import HyperLinkWithArrow from './subcomponents/HyperLinkWithArrow'

import { toggleRightModel, toggleScroll } from '../ReduxFiles/Counter/modelSlice'
import { useDispatch } from 'react-redux'

const Navbar = () => {
    const dispatch = useDispatch()
    const toggleModelfunc = () => {
        dispatch(toggleRightModel())
        dispatch(toggleScroll())
    }

    return (
        <>
            <Container>
                <div className="logo">
                    <h2>ANSDEV</h2>
                    <span>Beta</span>
                </div>
                <div className="links">
                    <HyperLinkWithArrow title="Home" to='Hero' />
                    <HyperLinkWithArrow title="About" to='About' />
                    <HyperLinkWithArrow title="Skills" to='Skill' />
                    <HyperLinkWithArrow title="Blogs" to='Blogs' />
                    <HyperLinkWithArrow title="Services" to='Services' />
                    <HyperLinkWithArrow title="Contact" to='Contact' />
                </div>
                <div className="profiles" onClick={toggleModelfunc}>
                    <h3>Aman<br /> Nishad</h3>
                    <img src="https://images.unsplash.com/photo-1592009309602-1dde752490ae?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&w=1000&q=80" alt="profile" />
                </div>

            </Container>
        </>
    )
}

export default Navbar

const Container = styled.div`
    position: sticky;
    top: 0;
    width: 100vw;
    height: 60px;
    margin-top: 20px;
    padding:5px;
    display: flex;
    z-index: 999;
    align-items: center;
    justify-content: space-between;
    background-color: #030312;
    @media (min-width:320px) and (max-width:376px) {
        height:40px;
    }
    @media (min-width:376px) and (max-width:426px) {
        height: 50px;
    }

    .logo{
        position: relative;
        h2{
            color: #FF5C00;
            margin-left: 20px;
            font-size: 2rem;

            @media (min-width:320px) and (max-width:376px) {
            font-size: 1rem;
            margin-left: 5px;
            } 
            @media (min-width:376px) and (max-width:426px){
            font-size: 1.5rem;
            margin-left: 8px;
            }
        }
        span{
            position: absolute;
            top: -5px;
            right: -10px;
            color: #ffffffcc;
            padding: 1px 5px;
            font-size: .6rem;
            border-radius: 10px;
            background-color: #030312;
            outline: 1px solid #ff5c00;
            @media (max-width:426px){
                top: -5px;
                right: -20px;
                font-size: .4rem;

            }
        }
    }


    .links{
       display: flex;
       align-items: center;
       gap: 50px; 
       @media (min-width:320px) and (max-width:768px) {
        display: none;
       }        
    }
    .profiles{
        position: relative;
        width: 160px;
        height: 100%;
        display: flex;
        justify-content: space-evenly;
        align-items: center;
        border-radius: 10px 0px 0 10px ;
        background-color: #FF5C00;
        cursor: pointer;
        transition:all .3s ease-in-out;
        &:hover{
            border: 1px solid #FF5C00;
            background-color:transparent;
            h3{

                color: #FF5C00 ;
            }
        }
        @media (min-width:320px) and (max-width:376px){
            width: 70px;
        }
        @media (min-width:376px) and (max-width:426px){
            width: 100px;
        }
      h3{
        font-size: .8rem;
        color: #fff;
        @media (min-width:320px) and (max-width:376px){
            font-size: .4rem;

        }
        @media (min-width:376px) and (max-width:426px){
            font-size: .6rem;

        }

      }
      img{
        width: 40px;
        height: 40px;
        border-radius: 50%;
        @media (min-width:320px) and (max-width:376px){
            width: 20px;
            height: 20px;
        }

        @media (min-width:375px) and (max-width:426px){
            width: 30px;
            height: 30px;
        }
      }
    }

`;