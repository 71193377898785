import React from 'react'
import { styled } from 'styled-components'
import Button from './subcomponents/Button'

const LImgDisBoxWithBorder = () => {
    return (
        <div data-AOS='fade'>
            <Container>
                <img data-AOS='fade-left' src="/Images/Image.png" alt="size" />
                <div className="discription " data-AOS='fade-right'>
                    <h3 data-AOS='fade'>Introduction</h3>
                    <p data-AOS='fade'>Welcome to my portfolio website! I am a versatile web developer with a strong skill set in a wide range of technologies, including ReactJS, Git, GitHub, HTML, CSS, JavaScript, Node.js, MongoDB, Styled Components, Figma, Canva, SEO, Bootstrap, Material UI, and Tailwind CSS. My passion lies in creating exceptional web a and delivering high-quality solutions to my clients.</p>
                    <Button title='Read More' p='2px 10px' variant='outline' />
                </div>
            </Container>
        </div>
    )
}

export default LImgDisBoxWithBorder

const Container = styled.div`
position: relative;
width: 80%;
display: flex;
margin: 0px 10%;
border-radius: 10px;
align-items: center;
border: 5px solid #863a0e;
overflow: hidden;
padding: 20px;

@media (max-width:426px){
    flex-wrap:wrap;
    height: auto;
}
img{
    width: 40%;
    padding: 0 50px;
    height: 100%;
    border-radius: 10px;
    @media (max-width:426px){
    width: 100%;
    height: 40%;
}
}
.discription{
    width: 60%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;

    @media (max-width:768px)
    {
        width: 100%;
    }
    p{
        color: #fff;
        font-size: 1rem;
        @media (max-width:768px)
    {
       font-size: .8rem;
       /* text-align: center; */
       margin-bottom:10px;
    }
    }
    h3{
        color: #ff5c00;
        text-transform:uppercase;
        font-size: 1rem;
        margin: 10px;
       text-align: center;
    }
}

`;
