import React from 'react'
import { styled } from 'styled-components'

const HeadingTitle = ({ category, title, id }) => {
    return (
        <Container data-AOS='zoom-in' id={id}><h2>{category} <i>|</i><span>{title}</span></h2></Container>
    )
}

export default HeadingTitle

const Container = styled.div`
    width: 100%;
    height: 50px;
    text-align: center;
    margin: 30px 0;
    @media (max-width: 768px){
        width: 100%;
        height: 30px;
    h2{
        font-size: 1rem;
    }
    }
    h2{
        font-size: 1.3rem;
        color: #ff5c00;
        @media (max-width:321px){
        font-size: .9rem;
        margin-top: 5px;
 
        }
        @media (max-width:426px){
        font-size: 1rem;
        margin-top: 5px;
 
        }
        i{
            height: 100%;
            width: 5px;
            font-weight: 700;
            color: #ff5c00;
            margin: 0 5px;

        }
        span{
            color: #fff;
            font-size: 1.3rem;
            font-weight: 600;
            @media (max-width:321px){
        font-size: .9rem;
        margin-top: 5px;
 
        }
        @media (max-width:426px){
        font-size: 1rem;
        margin-top: 5px;
 
        }
        }

    }

`