import React from 'react'
import { styled } from 'styled-components'
// import Button from './subcomponents/Button'
import HyperLinkWithArrow from './subcomponents/HyperLinkWithArrow'
import TypeWriter from './subcomponents/TyperText'
import AnimateSkillBox from './subcomponents/AnimateSkillBox'
import { MdDownloadForOffline } from 'react-icons/md'
import DownloadBtn from './subcomponents/DownloadBtn'
import Resume from '../Document/RightResume.pdf'

const LImgCard = () => {

    var data = ['Aman Nishad', 'Creative Designer', 'Web Devloper', 'Graphic Designer', 'UI / UX Designer', 'Mern Stack ', 'Developer', 'YouTuber', 'Blogger'];
    return (
        <Conatiner >
            <div className="discription">
                <h3 data-AOS={"fade-right"}>Hello I'm</h3>
                <h1><TypeWriter Data={data} /></h1>
                <h4 data-AOS={"fade-right"}>CodeCraft : A Journey in Software Development</h4>
                {/* <a download href={Resume}>ddddd</a> */}
                <DownloadBtn source={Resume} m='50px 0px 10px 0' startIcon={<MdDownloadForOffline />} title='Download CV' ScrollAnimation='fade' />
                <HyperLinkWithArrow title='Hire for our project click here ' fontSize='.8rem' color='#ff5c00' />
                <AnimateSkillBox />

            </div>
            <div className="imgBox " data-AOS={"fade-left"}>
                <img src="/Images/Image.png" alt="size" />
            </div>
        </Conatiner>
    )
}

export default LImgCard

const Conatiner = styled.div`
position: relative;
margin-top: 20px;
width: 100%;
display: flex;
align-items: center;
justify-content: space-between;
@media (max-width: 426px){
   flex-wrap:wrap;
   gap: 20px;

 }
&::after{
    content: '';
    position: absolute;
    bottom: 200px;
    left: -130px;
    width: 300px;
    height: 300px;
    border-radius:450%;
    background-color: #652b09;
    z-index: -1;
    @media (max-width: 768px){
        position: relative;
      width: 200px;
      height: 200px;
      bottom: 300px;
      left: -150px;

    }
}
.discription{
    width: 60%;
    height: 100%;
    padding-top: 100px;
    display: flex;
    flex-direction: column;
    align-items: center;
    @media (max-width: 426px) and (min-width: 320px){
        width: 100%;
        height: auto;
        padding-top: 10px;

    }
   h3{
    color: #fff;
    font-size: 2rem;
    @media (max-width: 768px){
        font-size: 1.2rem;
    }
    @media (max-width: 426px) and (min-width: 320px){
        font-size: 1rem;
    }
    @media (max-width: 320px){

        font-size: .8rem;
    }
   }
   h1{
    color: #FF5C00;
    font-size: 4.5rem;
    white-space: nowrap;
    @media (max-width: 1024px) and (min-width: 768px){
    font-size: 4rem;
    }
    @media (max-width: 768px) and (min-width: 425px){
        font-size: 2rem;
    }
    @media (max-width: 426px) and (min-width: 320px){
        font-size: 1.5rem;
    }
    @media(min-width: 320px) and (max-width:375px){
        font-size: 1rem;
    }
   }
   h4{
    color: #fff;
    margin: 10px 0;
    @media (max-width: 768px) and (min-width: 320px){
    font-size: .6rem;
  
   }}
  
}
.imgBox{
    width: 40%;
    height: 90%;
    margin: 30px 10%;
    background-color: #fff;
    border-radius: 10px;
    overflow: hidden;
    @media (max-width:426px){
        width: 100%;
        height: 70%;
    }
    @media (min-width: 426px) and (max-width:768px){
        height: 70%;
       
    }
   }
   img{
    width: 100%;
    height: 100%;
   }
`;