import { createSlice } from "@reduxjs/toolkit";

export const modelSlice = createSlice({
    name: 'model',
    initialState: {
        value: 'PopUpmodel',
        popcard: 'close',
        rightModel: 'rclose',
        scrollNone: false,
    },
    reducers: {
        openModel: (state) => {
            state.value = '';
        },
        closeModel: (state) => {
            state.value = 'PopUpmodel';
        },
        toggleModel: (state) => {
            if (state.value === 'PopUpmodel') {
                state.value = '';
            } else {
                state.value = 'PopUpmodel';
            }
        },
        toggleCardModel: (state) => {
            if (state.popcard === 'close') {
                state.popcard = '';
            } else {
                state.popcard = 'close';
            }
        },
        toggleRightModel: (state) => {
            if (state.rightModel === 'rclose') {
                state.rightModel = '';
            } else {
                state.rightModel = 'rclose';
            }
        },
        toggleScroll: (state) => {
            if (state.scrollNone === true) {
                state.scrollNone = false;
            } else {
                state.scrollNone = true;
            }
        },

    },
})


// Action creators are generated for each case reducer function
export const { openModel, closeModel, toggleModel, toggleCardModel, toggleScroll, toggleRightModel } = modelSlice.actions
export default modelSlice.reducer