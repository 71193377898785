import React from 'react'
import { Link } from 'react-scroll'
import { styled } from 'styled-components'

const HyperLinkWithArrow = ({ title, fontSize, color, lineColor, to }) => {
  return (
    <HyperLink fontSize={fontSize} color={color} lineColor={lineColor} className='hyperlink'>
      <Link to={to} smooth={true} duration={1000} offset={-90} activeClass='active' spy={true} >{title}
        <span />
      </Link>
    </HyperLink>
  )
}

export default HyperLinkWithArrow


const HyperLink = styled.div`
a {
        position: relative;
        text-decoration:none;
        color:${(props) => props.color === undefined ? "#fff" : props.color} ;
        font-size: ${(props) => props.fontSize === undefined ? "1rem" : props.fontSize};
        @media (max-width:321px){
        font-size: .7rem;
 
        }
        @media (max-width:425px){
        font-size: .8rem;
        margin-top: 5px;
 
        }
  
        &:hover span{
        scale: 1;
        }
        span{
        position: absolute;
        bottom: -3px;
        left: 0;
        width: 100%;
        height: 2px;
        scale: 0;
        border-radius: 4px;
        transition:.5s ease-in-out;
        background-color: ${(props) => props.lineColor === undefined ? '#ff5c00' : props.lineColor};
       }
       }
       .active {
        color: #ff5c00;
}

.active span{
        scale: 1;
}
`;