import React, { useState } from 'react'
import { styled } from 'styled-components'
import Card from './subcomponents/Card'
import { useDispatch, useSelector } from 'react-redux'
import { toggleCardModel, toggleScroll } from '../ReduxFiles/Counter/modelSlice'

const Blogs = () => {
    const open = useSelector((state) => state.model.popcard)
    const [isOpen, setIsOpen] = useState(open)
    const dispatch = useDispatch();
    const PopUpModel = () => {
        dispatch(toggleCardModel());
        dispatch(toggleScroll());

    }
    return (
        <Container>
            <Card func={PopUpModel} />
            <Card func={PopUpModel} />
            <Card func={PopUpModel} />
            <Card func={PopUpModel} />
        </Container>
    )
}

export default Blogs

const Container = styled.div`
width: 100%;
height: auto;
margin: 20px 0;
display: flex;
gap: 50px;
flex-wrap:wrap;
/* align-items: center; */
justify-content: space-evenly;
@media (max-width:426px){
    height: auto;
    flex-wrap: wrap;
    gap: 20px;

}

`;
