import React from 'react'
import { Link } from 'react-router-dom'
import { styled } from 'styled-components'

const Card = ({ func }) => {
    return (
        <Container data-AOS='fade-down'>
            <Link onClick={func}>Web</Link>
            <img src="/Images/com.png" alt="" />
            <h2>World Technology</h2>
            <p>Versatile Graphic Designer  Elevating Brands and Concepts with Exceptional Artistry and Strategic Design Solutions.</p>
            {/* <div>Demo Content</div> */}
        </Container>
    )
}

export default Card

const Container = styled.div`
position: relative;
 width: 300px;
 height: 300px;
 border-radius: 10px;
 background-color: #444;
 overflow: hidden;
 &:hover div{
   scale: 1;

 }
 @media (max-width:426px){
    width: 250px;
    height: 250px;
    box-shadow: inset 0px -28px 30px -22px #bcb2b2e1;
}
a{
    position: absolute;
    top: 10px;
    right: 10px;
    background-color: #ff5c00;
    font-size: .6rem;
    color: #fff;
    text-decoration: none;
    padding: 4px 10px;
    border-radius: 20px;
}
div{
   position:absolute;
   scale: 0;
   top: 0;
   left: 0;
   width: 100%;
   height: 100%;
   display:flex;
   align-items: center;
   justify-content: center;
   font-size: 2rem;
   font-weight: 500;
   background-color: #ff5e00a7;
    transition:all .5s;
}
 img{
    width: 100%;
    height: 50%;
 }
 h2{
    color: #ff5c00;
    font-size: 1.2rem;
    padding: 5px 10px;
    @media (max-width:426px){
        font-size: 1rem;

    }
 }
 p{
    color: #f0f0f093;
    font-size: .8rem;
    padding: 5px 10px;
    @media (max-width:426px){
        font-size: .6rem;

    }
 }

`;