import React from 'react'
import { styled } from 'styled-components'
import FooterBtn from './MobSubComponents/FooterBtn';
import { AiFillHome, AiOutlineMenu } from 'react-icons/ai';
import { BsReverseLayoutTextWindowReverse } from 'react-icons/bs';
import { FaGraduationCap } from 'react-icons/fa';
import { useDispatch } from 'react-redux';
import { toggleRightModel, toggleScroll } from '../ReduxFiles/Counter/modelSlice';

export const MobFooter = () => {
    const dispatch = useDispatch()
    const toggleModelfunc = () => {
        dispatch(toggleRightModel())
        dispatch(toggleScroll())
    }
    return (
        <Container>
            <FooterBtn activeClass='active' icon={<AiFillHome />} to='Hero' title='Home' />
            <FooterBtn icon={<BsReverseLayoutTextWindowReverse />} to='Blogs' title='Blogs' />
            <FooterBtn icon={<FaGraduationCap />} to='Skill' title='Skills' />
            <FooterBtn func={toggleModelfunc} icon={<AiOutlineMenu />} title='Menu' />


        </Container>
    )
}
const Container = styled.div`
display: none;
@media (max-width: 768px){
display: block;
position: fixed;
width: 80%;
height: 50px;
bottom: 20px;
left: 10%;
border-radius: 50px;
display: flex;
align-items: center;
justify-content: space-evenly;
background-color: aliceblue;
z-index: 999;
}

`;