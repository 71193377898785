import React from 'react'
import { Link } from 'react-router-dom'
import { styled } from 'styled-components'

const SocialLink = ({ src, icon, color, title }) => {
    return (
        <Container data-AOS='fade' color={color} >
            <Link className='disebled' to={src}>{icon}
                <span>{title}</span>
            </Link>
        </Container>
    )
}

export default SocialLink

const Container = styled.div`
width: 50%;
height: 100px;
display: flex;
align-items: center;
justify-content: space-between;
padding: 0 20px;
@media (max-width:426px){
       width: 100%;

 
        }
a{
  position: relative;
  text-decoration: none;
  color: ${(props) => props.color === undefined ? '#ff5c00' : props.color};
  font-size: 30px;
  @media (max-width:426px){
        font-size: 1.2rem;
 
        }
  span{
    position: absolute;
    font-size: 0.7rem;
    bottom: -10px;
    left: -7px;
    scale: 1;
    transition: all .5s ;
}
&:hover span{
scale: 1;
}
@media (max-width:426px){
       span{
        scale: 1;
        font-size: .5rem;
       }
 
        }
}

`;