import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import AOS from 'aos';
import 'aos/dist/aos.css'; // You can also use <link> for styles
import { Provider } from 'react-redux';
import store from './ReduxFiles/store';
// ..
AOS.init();
AOS.init({
  offset: 160,
  duration: 1000,
  easing: 'ease-in-out'

});
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <Provider store={store}>
      <App />
    </Provider>
  </React.StrictMode>
);
reportWebVitals();
