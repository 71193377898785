import React from 'react'
import { styled } from 'styled-components'

const ProjectCounter = ({ title, count }) => {
    return (
        <Container data-AOS='fade-down'>
            <div data-AOS='zoom-in' className="circle">
                <h1 data-AOS='fade'>{count}</h1>
            </div>

            <h3 data-AOS='fade'>{title}</h3>
        </Container>
    )
}

export default ProjectCounter

const Container = styled.div`
.circle{
width: 200px;
height: 200px;
border-radius: 50%;
border: 2px solid #ff5c00;
z-index: 9;
display: flex;
align-items: center;
justify-content: center ;

@media (max-width:426px){
    width: 100px;
    height: 100px;
}
h1{
    color: #ff5c00;
    font-size: 3rem;
    @media (max-width:426px){
    font-size: 1.4rem;
}
}
}
h3{
    color: #ff5c00;
    margin: 10px ;
    text-align: center;
    @media (max-width:426px){
    font-size: .7rem;
}
}

`;