import React from 'react'
import { styled } from 'styled-components'
// import Button from './Button'
import { MdDownloadForOffline } from 'react-icons/md'
import Resume from '../../Document/RightResume.pdf'
import DownloadBtn from './DownloadBtn'

const LImgAndDis = () => {
    return (
        <Container>
            <img data-AOS='fade-right' src="/Images/Coding.png" alt="size" />
            <div className="discription" data-AOS='fade-left'>
                <h3 data-AOS='fade'>About Me</h3>
                <h1 data-AOS='fade'>I'm <span>Aman Nishad </span></h1>
                <h4 data-AOS='fade'>Software Developer & Creative Graphics Designer</h4>
                <p className='location' data-AOS='fade-left'>Based in Uttar Pradesh , INDIA</p>
                <p data-AOS='fade'>Hi! My name is Aman Nishad. I am UI/UX designer, and I'm very passionate and dedicated to my work.</p>
                <p data-AOS='fade'>With 2 years experience as a professional graphic designer and web developer, I have acquired the skills and knowledge necessary to make your project a success.</p>
                <DownloadBtn source={Resume} m='50px 0px 10px 0' startIcon={<MdDownloadForOffline />} title='Download CV' ScrollAnimation='fade' />

            </div>
        </Container>
    )
}

export default LImgAndDis

const Container = styled.div`
position: relative;
width: 100%;
height: 100%;
display: flex;
column-gap:100px;

@media (max-width:426px){
        padding: 10px;
        flex-wrap: wrap;
 
        }

img{
    margin-bottom: 100px;
    margin-left: 120px;
    border-radius: 10px;
    width: 400px;
    height: 60%;
    @media (max-width:426px){
        width: 100%;
        height: 300px;
        margin: 10px;
        }

}
.discription{
    width: auto;
    height: 100%;
    h3{
        color: #ff5c00;
        @media (max-width:426px){
        font-size: 1rem;
 
        }

    }
    h1{
        color: #ff5c00;
        font-size: 4rem;
        @media (max-width:426px){
        font-size: 2rem;
 
        }
        span{
            color: #fff;
        }
    }
    h4{
        color: #fff;
        font-size: 1.5rem;
        @media (max-width:426px){
        font-size: .6rem;
 
        }
    }
    .location{
        color: #ff5c00;
        margin: 5px;
        @media (max-width:426px){
        font-size: .7rem;
 
        }
    }
    p{ 
        width: 50%;
        margin-top: 20px;
        color: #fff;
        @media (max-width:426px){
        font-size: .8rem;
        width: 100%;
        }
    }
}



`;