import React from 'react'
import { styled } from 'styled-components'
import InputBox from './subcomponents/InputBox'
import TextAreaBox from './subcomponents/TextAreaBox'
import SubmitBtn from './subcomponents/SubmitBtn'
import SocialLink from './subcomponents/SocialLink'
import { FaFacebook, FaGithubAlt, FaInstagram, FaTwitter, FaYoutube } from 'react-icons/fa'

const Contact = () => {
    return (
        <Container>
            <img data-AOS='fade-right' src="/Images/Image.png" alt="" />
            <form className="form" data-AOS='fade-left'>
                <h1>Contact</h1>
                <InputBox type='text' placeholder='Full Name' required={true} />
                <InputBox type='email' placeholder='Email' required={true} />
                <TextAreaBox row='5' placeholder='Message' required={true} />
                <div className='flex'>
                    <SubmitBtn title='Submit' m='20px 100px' width='250px' />
                    <div className="flex w">
                        <SocialLink title='Youtube' icon={<FaYoutube />} color='#f00' />
                        <SocialLink title='GitHubs' icon={<FaGithubAlt />} color='#000' />
                        <SocialLink title='Facebook' icon={<FaFacebook />} color='#1877F2' />
                        <SocialLink title='Instagram' icon={<FaInstagram />} color='#AD34B0' />
                        <SocialLink title='Twitter' icon={<FaTwitter />} color="#1DA1F2" />
                    </div>
                </div>

                <div className="titleBox">
                    <h4>E-Mail</h4>
                    <p>amannishad1443@gmail.com</p>
                </div>
                <div className="titleBox">
                    <h4>Based in </h4>
                    <p>Aman Nishad - Uttar Pradesh , INDIA</p>
                </div>

            </form>
        </Container>
    )
}

export default Contact

const Container = styled.div`
position: relative;
width: 100%;
height: 600px;
background-color: #f9f9f9;
display: flex;
flex-wrap: wrap;
margin-top: 50px;
@media (max-width:426px){
height: auto;
margin-bottom:100px;
  
}
img{
    width: 40%;
    height: 100%;
    border-radius: 0 50px 50px 0;
    @media (max-width:426px){
    width: 100%;
    height: 300px;
    border-radius: 0px;
 
}
}
.form{
    width: 60%;
    height: 100%;
    @media (max-width:426px){
    width: 100%;
    height: auto;
    border-radius: 0px;
    
 
}
    h1{
        text-align: center;
        margin: 10px 0;
        color: #ff5c00;
        font-weight: 600;
        @media (max-width:426px){
        font-size: 1.2rem;
 
        }

    }
    .flex{
        display: flex;
        width: 50%;
        justify-content: space-between;
        align-items: center;
        gap: 10px;
        @media (max-width:426px){
         width: 100%;
        flex-wrap: wrap;

        }
    }
    @media (max-width:426px){
        .w{
            flex-wrap: nowrap;
        }
 
        }

    .titleBox{
        margin: 10px 100px;
        @media (max-width:426px){
        margin: 10px;
 
        }

        h4{
            color: #ff5c00;
            font-size: 1rem;
            @media (max-width:426px){
        font-size: .8rem;
 
        }

        }
        p{
            font-size: 0.8rem;
            color: #444;
            @media (max-width:426px){
            font-size: .5rem;
 
        }
        }
    }
 
}  
`;
