import React from 'react'
import { styled } from 'styled-components'
import { TiWeatherPartlySunny } from 'react-icons/ti'

const WeatherBox = ({ id }) => {
    return (
        <Container id={id}>
            <h5 className='welc'>Welcome</h5>
            <h5 className='daytrack'>To My World !!</h5>
            <i><TiWeatherPartlySunny /></i>

        </Container>
    )
}

export default WeatherBox

const Container = styled.div`
position: relative;
width: 80%;
margin:30px 10%;
height: 200px;
display: flex;
align-items: center;
flex-direction: column;
justify-content: flex-start;
border-radius: 10px;

.welc{
        color: #fff;
        font-size: 2rem;

}
.daytrack{
    color: #fff;


}
i{
    font-size: 120px;
    color: #0fccf7e5;
}
`;