import React from 'react'
import { styled } from 'styled-components'
import { MdClose } from 'react-icons/md'
import { Link } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { toggleRightModel, toggleScroll } from '../ReduxFiles/Counter/modelSlice'
import SideLink from '../components/subcomponents/SideLink'
import { AiFillProject, AiOutlineFacebook, AiOutlineFileProtect, AiOutlineInstagram, AiOutlineLinkedin, AiOutlineMail, AiOutlineYoutube } from 'react-icons/ai'
import { SiFreelancer, SiGithub, SiHubspot, SiIndeed } from 'react-icons/si'
import { LiaBlogSolid } from 'react-icons/lia'


const SideBarWithWraper = () => {
    const open = useSelector((state) => state.model.rightModel)
    const dispatch = useDispatch();
    const togglehendle = () => {
        dispatch(toggleScroll());
        dispatch(toggleRightModel());
    }

    return (
        <>
            {
                open === '' ? <Wrapper onClick={togglehendle} /> : ''
            }
            <Container className={open}>
                <div className="navbar">
                    <h2>ANSDEV</h2>
                    <i onClick={togglehendle}><MdClose /></i>
                </div>
                <div className="container">
                    <SideLink startIcon={<AiOutlineFileProtect />} title='Complated Projects' />
                    <SideLink startIcon={<AiFillProject />} title='Work & Projects' />
                    <SideLink startIcon={<LiaBlogSolid />} title='My Blogs' />
                    <SideLink startIcon={<SiHubspot />} title='My Portfolio' />
                    <SideLink startIcon={<SiGithub />} title='GitHubs' />
                    <SideLink startIcon={<AiOutlineYoutube />} title='Youtube' />
                    <SideLink startIcon={<AiOutlineInstagram />} title='Instagram' />
                    <SideLink startIcon={<AiOutlineFacebook />} title='Facebook' />
                    <SideLink startIcon={<AiOutlineLinkedin />} title='Linkedin' />
                    <SideLink startIcon={<SiIndeed />} title='Indeed' />
                    <SideLink startIcon={<SiFreelancer />} title='Freelancing' />
                    <SideLink startIcon={<AiOutlineMail />} title='Contact Me' />
                </div>
                <div className="footer">
                    <div className='hyperLink'><Link>Privacy</Link></div>
                    <div className='hyperLink'><Link>Terms & Condition</Link> </div>
                    <div className='hyperLink'><Link>FAQ</Link></div>
                </div>
            </Container>
        </>
    )
}

export default SideBarWithWraper

const Wrapper = styled.div`
/* display: none; */
position: fixed;
top: 0;
left: 0;
width: 100%;
height: 100%;
background-color: #f5a11940;
z-index: 999;
`;

const Container = styled.div`
position: fixed;
top: 0;
right: 0%;
width: 250px;
height: 100%;
background-color: #000;
z-index: 9999;
transition: all .5s ease-in-out;
@media (max-width:426px){
    right: 1%;

            

        }
.navbar{
    width: 100%;
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 10px;
    border-bottom:.5px solid #ffffff7c;

    h2{
        color: #ff5c00;
        cursor: default;
    }
    i{
        color: #fff;
        padding: 3px;
        font-size: 23px;
        border: 1px solid #fff;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        transition: all .3s;
        &:hover{
            color: #ff5c00;
            border-color: #ff5c00;
        }
        @media (max-width:426px){
            font-size: 18px;


        }

    }
   
}
.container{
    padding:0;
   .settingOption{
    width: 100%;
    padding: 0 10px;
    height: 40px;
    display: flex;
    align-items: center;
    transition: all .5s ease-in-out;

    &:hover{
        background-color: #ff5e0020;
        a{
            text-transform:uppercase;
        }
    }

    a{
        text-decoration:none;
        color: #e0d8d8;
        font-size: 0.8rem;
        transition: all .5s ease-in-out;

    }
   }
}
.footer{
    width: 100%;
    height: auto;
    border-top: 1px solid #ffffff7c;
    .hyperLink{
        width: 100%;
        padding: 5px 10px;
      a{
        text-decoration: none;
        color: #cecdcd;
        font-size: 12px;
        transition: all .3s;
        &:hover{
            text-decoration: underline;
            color: #ff5c00;
        }
      }
    }
}

`;