import React from 'react'
import { styled } from 'styled-components'

const TextAreaBox = ({ required, row, col, placeholder }) => {
    return (
        <Container>
            <textarea placeholder={placeholder} cols={col} rows={row}></textarea>
        </Container>
    )
}

export default TextAreaBox

const Container = styled.form`
textarea{
        width: 80%;
        margin: 20px 10% ;
        padding: 10px 15px;
        border-radius: 20px;
        border: 2px solid #ff5c00;
        /* background-color: #ff5e00fd; */
        font-size: 1rem;
        color: #222;
        transition: all.5s ;
        resize: none;
        overflow: hidden;
        &::placeholder{
            color: #222222b8;
            font-size: 1rem;
            font-weight: 600;

        }
        &:focus{
            outline: 1px solid #ff5c00;
            border-color: transparent;
            background-color: transparent;
        }
        &:hover{
            outline: 1px solid #ff5c00;
            background-color: #ff5e0025;
        }
        &::-webkit-scrollbar{
            width: 5px;
            
        }
        &::-webkit-scrollbar-track{
            background-color: transparent;
        }
        &::-webkit-scrollbar-thumb{
            background-color: #222;
            border-radius: 10px;
        }



    } 
    
`;