import React from 'react'
import { styled } from 'styled-components'
import ServicesBox from './subcomponents/ServicesBox'
import { MdOutlineSettingsSuggest } from 'react-icons/md'
import { CgWebsite } from 'react-icons/cg'
import { DiResponsive } from 'react-icons/di'

const Services = () => {
    return (
        <Container data-AOS='fade'>
            <ServicesBox title='Creative Designer' icon={<MdOutlineSettingsSuggest />} discription='Versatile Graphic Designer  Elevating Brands and Concepts with Exceptional Artistry and Strategic Design Solutions.' />
            <ServicesBox title='Web Developer' icon={<CgWebsite />} discription='Professional Web Developer Expertly Crafting Innovative and Seamless Web Solutions to Elevate User Experiences.' />
            <ServicesBox title='Responsive Design' icon={<DiResponsive />} discription='Seamless User Experience: Mastering Responsive Design for Modern Multi-Device World' />
        </Container>
    )
}

export default Services

const Container = styled.div`
  width: 100%;
  height: 400px;
  margin-top: 50px;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  @media (max-width:426px){
   flex-wrap: wrap;
   height: auto;
   gap: 20px;
}


`;