import React from 'react'
import { styled } from 'styled-components'
import StarGraphics from './subcomponents/StarGraphics'
import LImgAndDis from './subcomponents/LImgAndDis'

const About = () => {
    return (

        <Container>
            <StarGraphics />
            <LImgAndDis />
        </Container>


    )
}

export default About

const Container = styled.div`
position: relative;
padding: 10px;
margin-top: 50px;
width: 100%;
`;
