import React from 'react'
// import { Link } from 'react-router-dom'
import { styled } from 'styled-components'


const DownloadBtn = ({ m, p, variant, bgcolor, color, startIcon, title, ScrollAnimation, source }) => {
    return (
        <Container m={m} p={p} variant={variant} color={color} bg={bgcolor} >
            <a download='Resume' href={source}>{title} {startIcon}</a>
        </Container>
    )
}

export default DownloadBtn


const Container = styled.div`
a{
    text-decoration: none;
    background-color: ${(props) => props.variant === "outline" ? 'transparent' : props.bg === undefined ? '#ff5c00' : props.bg};
    border: ${(props) => props.variant === 'outline' ? '1px solid #ff5c00' : '1px solid #ff5c00'};
    padding:${(props) => props.p === undefined ? '5px 20px' : props.p};
    font-size: 1rem;
    margin: ${(props) => props.m === undefined ? '2px' : props.m};
    border-radius: 50px;
    font-weight: 600;
    max-width: 400px;
    color:  ${(props) => props.variant === 'outline' ? '#ff5c00' : props.color === undefined ? '#fff' : props.color};
    display: flex;
    align-items: center;
    justify-content: center;
    transition: .4s ease-in-out;
    @media(min-width: 320px) and (max-width:376px){
    font-size: .7rem;
    padding: 3px 10px;
     
    }
    @media(min-width: 375px) and (max-width:768px){
    font-size: .8rem;
    padding: 4px 12px;
     
    }
    &:hover{
    background-color: ${(props) => props.variant === undefined ? 'transparent' : props.bg === undefined ? '#ff5c00' : props.bg};
    color:  ${(props) => props.variant === undefined ? '#ff5c00' : props.color === undefined ? '#fff' : props.color};
    border: ${(props) => props.variant === undefined ? '1px solid #ff5c00' : '1px solid tranparent'};
    @media(min-width: 320px) and (max-width:376px){
    font-size: .7rem;
    padding: 3px 10px;
     
    
    }
    @media(min-width: 375px) and (max-width:768px){
    font-size: .8rem;
    padding: 4px 12px;
     
    }
}
    svg{
        margin-left: 10px;
        font-size: 1.5rem;
        @media(min-width: 320px) and (max-width:376px){
        font-size: .7rem;
     
    
    }
    }
}
`;