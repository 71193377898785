import React from 'react'
import Navbar from '../components/Navbar'
import RImgCard from '../components/RImgCard'
import { MobFooter } from '../MobileComponents/MobFooter'
import WeatherBox from '../components/subcomponents/WeatherBox'
import LImgDisBoxWithBorder from '../components/LImgDisBoxWithBorder'
import HeadingTitle from '../components/subcomponents/HeadingTitle'
import About from '../components/About'
import SkillsBox from '../components/SkillsBox'
import Contact from '../components/Contact'
import Services from '../components/Services'
import RealProject from '../components/subcomponents/RealProject'
import Blogs from '../components/Blogs'
import Sidebar from '../Models/Sidebar'
import ModelCard from '../Models/ModelCard'
import SideBarWithWraper from '../Models/SideBarWithWraper'
import { styled } from 'styled-components'
import { useSelector } from 'react-redux'
import TopBtn from '../components/subcomponents/TopBtn'

const Home = () => {
  const scrollHide = useSelector((state) => state.model.scrollNone);
  return (
    <Container hide={scrollHide}>
      <Navbar />
      <TopBtn />
      <WeatherBox id='Hero' />
      <RImgCard />
      <MobFooter />
      <LImgDisBoxWithBorder />
      <HeadingTitle category='About Me' title='Welcome Digital World' id='About' />
      <About />
      <HeadingTitle category='My Skills' title='Project Work' id='Skill' />
      <SkillsBox />
      <HeadingTitle category='Blogs ' title='Creative Ideas' id='Blogs' />
      <Blogs />
      <HeadingTitle category='Services ' title='Clients' id='Services' />
      <Services />
      <HeadingTitle category='Real World Project ' title='Skill Analytics' id='RealProject' />
      <RealProject />
      <HeadingTitle category='Contact ' title='Connect with Me' id='Contact' />
      <Contact />
      <Sidebar />
      <SideBarWithWraper />
      <ModelCard />
    </Container>

  )
}

export default Home
const Container = styled.div`
width: 100%;
height: ${(props) => props.hide === false ? 'auto' : '100vh'};
overflow-x:hidden;
overflow-y:${(props) => props.hide === false ? 'auto' : 'hidden'};

`;