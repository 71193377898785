import React from 'react'
import { styled } from 'styled-components'
const ServicesBox = ({ icon, title, discription }) => {
    return (
        <Container data-AOS='fade-down'>
            {icon}
            <h1 data-AOS='fade'>{title}</h1>
            <p data-AOS='fade'>{discription}</p>
        </Container>
    )
}

export default ServicesBox

const Container = styled.div`
width: 350px;
height: 300px;
background-color: #444;
border-radius: 10px;
padding: 10px 20px;
@media (max-width:426px){
    width: 250px;
    height: 250px;
}

svg{
    font-size: 80px;
    color: #ff5c00;
    @media (max-width:426px){
    font-size: 40px;
    margin-top: 20px;
}
}
h1{
    color: #f0f0f0;
    font-size: 1.6rem;
    padding: 0 10px;
    margin: 20px 0;
    @media (max-width:426px){
    font-size: 1.2rem;
}
}
p{
    padding: 0 10px;
    font-size: .8rem;
    color: #ffffffae;
    @media (max-width:426px){
    font-size: .5rem;
}
}
`;
