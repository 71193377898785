import React from 'react'
import { styled } from 'styled-components';
import { Link } from "react-scroll";
const FooterBtn = ({ activeClass, icon, title, func, to }) => {
  return (
    <Container >
      <Link onClick={func} to={to} smooth={true} duration={1000} offset={-90} activeClass='active' spy={true} >

        <span>
          {icon}
        </span>        {/* <span className={activeClass}>{title}</span> */}
      </Link>
    </Container>
  )
}

export default FooterBtn

const Container = styled.div`
 a{

  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
 
 
  /* .active{
    display: block;
  } */
  /* span{
    display: none;
    font-size: .6rem;
    background-color: #030312;
    padding: 1px 8px;
    border-radius: 10px;
    color: #fff;

    &:active{
    scale: 1;
    } */
    span{
    color: #030312;
    font-size: 1.6rem;
  
 }
 
}
.active{
        span{
          color: #ff5c00;
        }
  }

`;
