import React from 'react'
import { styled } from 'styled-components'
import HyperLinkWithArrow from '../components/subcomponents/HyperLinkWithArrow'
import { useSelector } from 'react-redux'


const Sidebar = () => {
    const open = useSelector((state) => state.model.value)

    return (
        <Container className={open}>
            <HyperLinkWithArrow title="Home" to='Hero' />
            <HyperLinkWithArrow title="About" to='About' />
            <HyperLinkWithArrow title="Skills" to='Skill' />
            <HyperLinkWithArrow title="Blogs" to='Blogs' />
            <HyperLinkWithArrow title="Services" to='Services' />
            <HyperLinkWithArrow title="Contact" to='Contact' />
        </Container>
    )
}

export default Sidebar

const Container = styled.div`
position: fixed;
right: 10px;
top: 70px;
width: 250px;
height: 400px;
z-index: 999;
border-radius: 10px;
padding: 10px;
display: flex;
flex-direction: column;
align-items: center;
border: 2px solid #ff5c00;
transition: all .5s ease-in;
background-color: #1d221d;
&.PopUpmodel {
    right: -250px;
}
.hyperlink{
    width: 100%;
    margin: 10px;
    a span{
       scale: 0;
    }
}




`;