import React from 'react'
import { Link } from 'react-router-dom'
import { styled } from 'styled-components'

const SideLink = ({ title, src, startIcon }) => {
    return (
        <Container>
            {startIcon}
            <Link to={src}>{title}</Link>
        </Container>
    )
}

export default SideLink
const Container = styled.div`
    width: 100%;
    padding: 0 10px;
    height: 40px;
    color: #e0d8d8;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 10px;
    transition: all .5s ease-in-out;

    &:hover{
        background-color: #ff5e0020;
        color: #ff5c00;
        a{
            text-transform:uppercase;
        }
    }

    a{
        text-decoration:none;
        color: #e0d8d8;
        font-size: 0.8rem;
        transition: all .5s ease-in-out;

    }
   
`;