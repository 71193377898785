import React from 'react'
import { styled } from 'styled-components'
import SkillCard from './subcomponents/SkillCard'
import { FaCss3, FaFigma, FaGitAlt, FaGithubAlt, FaHtml5, FaJs, FaNode, FaReact } from 'react-icons/fa'
import { SiBootstrap, SiCanva, SiMui, SiStackbit, SiStyledcomponents, SiTailwindcss } from 'react-icons/si'
import { RiSeoFill } from 'react-icons/ri'
import { BiSolidPlanet } from 'react-icons/bi'

const SkillsBox = () => {
    return (
        <Container>
            <h1 data-AOS='fade'>You Can ImagineI Can Do</h1>
            <p data-AOS='fade'>I am working on a professional, visually sophisticated and technologically proficient, responsive and multi-functional creative personal resume portfolio Aman Nishad .</p>
            <div className="FlexBox">
                <SkillCard title='React Js' percetage='99%' icon={<FaReact />} color='#00F0FF' />
                <SkillCard title='HTML/HTML5' percetage='99%' icon={<FaHtml5 />} color='#f06529' />
                <SkillCard title='CSS/CSS3' percetage='99%' icon={<FaCss3 />} color='#2965f1' />
                <SkillCard title='Java Script' percetage='99%' icon={<FaJs />} color='#F0DB4F' />
                <SkillCard title='Node Js' percetage='50%' icon={<FaNode />} color='#3c873a' />
                <SkillCard title='Git' percetage='98%' icon={<FaGitAlt />} color='#F1502F' />
                <SkillCard title='GitHub' percetage='98%' icon={<FaGithubAlt />} color='#e1e1e1 ' />
                <SkillCard title='Figma' percetage='98%' icon={<FaFigma />} color='#0f4fd8 ' />
                <SkillCard title='Canva' percetage='98%' icon={<SiCanva />} color='#005daa  ' />
                <SkillCard title='Styled-Components' percetage='90%' icon={<SiStyledcomponents />} color='#9a07b4  ' />
                <SkillCard title='Material UI' percetage='78%' icon={<SiMui />} color='#046dde  ' />
                <SkillCard title='Tailwind CSS' percetage='78%' icon={<SiTailwindcss />} color='#08c4ea  ' />
                <SkillCard title='Bootstrap' percetage='99%' icon={<SiBootstrap />} color='#9608ee  ' />
                <SkillCard title='SEO' percetage='99%' icon={<RiSeoFill />} color='#08b4ee  ' />
                <SkillCard title='Web Browser' percetage='99%' icon={<BiSolidPlanet />} color='#196dff  ' />
                <SkillCard title='Mern Stack Developer' percetage='100%' icon={<SiStackbit />} color='#196dff  ' />
            </div>

        </Container>
    )
}

export default SkillsBox

const Container = styled.div`
width: 100%;
height: 100%;
background-color: #ff5c00;
margin: 20px 0;
overflow: hidden;
 h1{
    text-align: center;
    padding: 20px 0;
    @media (max-width:426px){
        font-size: 1.2rem;
        padding: 10px 0;

 
        }
 }
 p{
    color: #fff;
    width: 56%;
    margin: 10px 22% ;
    text-align: center;
    font-size: 1.1rem;
    @media (max-width:426px){
        width: 100%;
        font-size: .6rem;
        margin: 5px ;
 
 
        }
 }
 .FlexBox{
    width: 100%;
    height: auto;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
    padding: 40px 50px;
    gap: 50px;
    @media (max-width:426px){
       padding: 10px;
       gap: 30px;
 
        }
 }
`;