import React from 'react'
import { styled } from 'styled-components';

const StarGraphics = () => {
    return (
        <Container>
            <div className="graphic">
                <svg className='star' xmlns="http://www.w3.org/2000/svg" width="227" height="191" viewBox="0 0 227 191" fill="none">
                    <path d="M113.5 0L140.105 72.8987L226.2 72.8987L156.548 117.953L183.153 190.851L113.5 145.797L43.8475 190.851L70.4524 117.953L0.799805 72.8987L86.8951 72.8987L113.5 0Z" fill="#ff5e00ca" />
                </svg>
                <h2 data-AOS='fade' title='My Responce Rate'>90%</h2>
                <svg className='trangle' xmlns="http://www.w3.org/2000/svg" width="158" height="176" viewBox="0 0 158 176" fill="none">
                    <path d="M79 0L157.808 175.5H0.191689L79 0Z" fill="#FF5C00" fill-opacity="0.53" />
                </svg>
                <div className='reactanle'></div>
            </div>
            <div className="circle"></div>

        </Container>
    )
}

export default StarGraphics

const Container = styled.div`
position: absolute;
right: 0;
bottom: 0;
width: 100%;
height: 80%;
margin: 10% 0;
overflow: hidden;
z-index: -1;
@media (max-width:426px){
      display: none;
 
        }

.graphic{
    width: 30%;
    position: absolute;
    right: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    flex-direction: column;
    .star{
        position: relative;
        top: 100px;
    }
    h2{
        color: #fff;
        z-index: 99;
        position: relative;
        top: -7px;
         left: 7px;
    }
    .reactanle{
    position: relative;
    width: 119px;
    height: 162px;
    flex-shrink: 0;
    left: -100px;
    top: -60px;
    background: rgba(255, 92, 0, 0.50);
  
}


}
.circle{
    position: absolute;
    bottom: 10px;
    left: -70px;
    width: 400px;
    height: 400px;
    border-radius: 50%;
    background-color: #ff5e0074;
}
`;