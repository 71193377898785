import React from 'react'
import { styled } from 'styled-components'

const SkillCard = ({ color, title, percetage, icon }) => {
    return (
        <div data-AOS='fade-up'>
            <Container color={color} percetage={percetage}>
                <i data-AOS='fade'>{icon}</i>
                <p className='title' data-AOS='fade'>{title}</p>
                <div data-AOS='fade' className="progress"></div>
                <span data-AOS='fade'>{percetage}</span>
            </Container>
        </div>
    )
}

export default SkillCard

const Container = styled.div`
position: relative;
width: 300px;
height: 300px;
background-color: #1E1E1E;
display: flex;
align-items: center;
flex-direction: column;
padding: 10px;
border-radius: 10px;
@media (max-width:426px){
        width: 150px;
        height: 150px;
 
        }
        @media (max-width:321px){
       width: 120px;
       height: 120px;
 
        }
svg{
    font-size: 80px;
    margin-top: 30px;
    color: ${(props) => props.color === undefined ? '#fff' : props.color};
    @media (max-width:426px){
        font-size: 30px;
        margin-top: 10px;
 
        }
}
.title{
    position: relative;
    width: 100%;
    white-space: nowrap;
    font-size: 1.2rem;
    text-align: center;
    color: ${(props) => props.color === undefined ? '#fff' : props.color};
    margin-top: 10px;
    @media (max-width:426px){
        font-size: 0.6rem;
        margin-top: 5px;
 
        }
       
}
.progress{
    position: relative;
    width: 80%;
    height: 10px;
    background-color: #ff5e007e;
    border-radius: 10px;
    overflow: hidden;
    margin: 30px 0;
    @media (max-width:426px){  
        margin: 5px 0;
        height: 5px;
 
        }
    &::after{
        content: '';
        position: absolute;
        width: ${(props) => props.percetage === undefined ? '0%' : props.percetage};
        height: 100%;
        top: 0;
        left: 0;
        background-color: ${(props) => props.color === undefined ? '#fff' : props.color};
        border-radius: 10px;
    }
  
}
span{
        font-size: 1.3rem;
        color:${(props) => props.color === undefined ? '#fff' : props.color};
        @media (max-width:426px){
        font-size: .7rem;
        margin-top: 5px;
 
        }
    }
`;