import React from 'react'
import { styled } from 'styled-components'
import ProjectCounter from './ProjectCounter'

const RealProject = () => {
    return (
        <Container>
            <ProjectCounter title='React Js Project' count={0} />
            <ProjectCounter title='Java Script Project' count={2} />
            <ProjectCounter title='Happy Client' count='98.8%' />
            <ProjectCounter title='Complate Project' count={2} />
        </Container>
    )
}

export default RealProject
const Container = styled.div`
width: 100%;
height: 350px;
padding: 10px;
display: flex;
align-items: center;
justify-content: space-evenly;
gap: 40px;
@media (max-width:426px){
    flex-wrap: wrap;
    height: auto;
}

`;