import React from 'react'
import { Typewriter } from 'react-simple-typewriter'

const TypeWriter = ({ Data }) => {
    return (
        <Typewriter
            words={Data}
            loop={Infinity}
            cursor
            cursorStyle='_'
            typeSpeed={120}
            deleteSpeed={100}
            delaySpeed={1000}
        // onLoopDone={handleDone}
        // onType={handleType}
        />

    )
}
export default TypeWriter