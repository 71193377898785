export const SkillData = [
    'React Js',
    'HTML / HTML5',
    'CSS / CSS3',
    'Java Script',
    'Node Js',
    'MongoDB',
    'Git',
    'GitHubs',
    'Styled-Components',
    'Tailwind CSS',
    'Boostrap',
    'Figma',
    'Canva',
    'Web Browser'

];
