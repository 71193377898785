import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { styled } from 'styled-components'
import { toggleCardModel, toggleScroll } from '../ReduxFiles/Counter/modelSlice'
import { MdClose } from 'react-icons/md';
import { AiFillProject } from 'react-icons/ai';
import { FaUserSecret } from 'react-icons/fa'
import { MdOutlineLanguage } from 'react-icons/md'
import { BiSolidHappyHeartEyes } from 'react-icons/bi'

const ModelCard = () => {
    const dispatch = useDispatch();
    const popModel = useSelector((state) => state.model.popcard)
    const togglehendle = () => {
        dispatch(toggleCardModel());
        dispatch(toggleScroll());
    }
    return (
        <>
            <Wraper className={popModel} onClick={togglehendle} />
            <Container className={popModel}>
                <div className="closeBtn" onClick={togglehendle}>
                    <MdClose />
                </div>
                <div className="Card ">
                    <h1 className='heading'>Chul Urina</h1>
                    <div className="flex">
                        <div className="leftSide">
                            <div className="list">
                                <span className='listTite'><AiFillProject /></span>
                                <span className='listTite'>Project : </span>
                                <span>Website</span>
                            </div>
                            <div className="list">
                                <span className='listTite'><FaUserSecret /></span>
                                <span className='listTite'>Client : </span>
                                <span>Evonta</span>
                            </div>

                            <div className="list">
                                <span className='listTite'><MdOutlineLanguage /></span>
                                <span className='listTite'>Language : </span>
                                <span>Website</span>
                            </div>

                            <div className="list">
                                <span className='listTite'><BiSolidHappyHeartEyes /></span>
                                <span className='listTite'>Preview : </span>
                                <Link>Website</Link>
                            </div>
                        </div>
                        <div className="rightSide">
                            <img src="/Images/Image.png" alt="size" />
                        </div>
                    </div>
                    <div className="discription">
                        <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Sed, fugiat. ipsum dolor sit amet consectetur adipisicing elit. Exercitationem accusamus nemo perferendis, vitae quasi sapiente possimus corrupti asperiores nobis dolore, quis beatae quaerat tempora maiores molestiae, quisquam quae ab porro? Distinctio rem, earum nesciunt quo autem illo animi recusandae laborum magnam laboriosam, deleniti ducimus corporis iste a adipisci perferendis tenetur.</p>
                    </div>

                </div>
            </Container>
        </>
    )
}

export default ModelCard

const Wraper = styled.div`

position: fixed;
top: 0;
left: 0;
width: 100%;
height: 100%;
background-color: #000000aa;
z-index: 9999;
`;

const Container = styled.div`
position: fixed;
top: 100px;
left: 20%;
width: 60%;
min-height: 400px;
background-color:#626060;
border-radius: 20px;
z-index: 9999;
padding: 10px;
transition: all .3s ease-in-out;
@media (max-width:426px){
    width: 100%;
    left: 0%;


}
.closeBtn{
    position: absolute;
    right: -50px;
    height: 40px;
    width: 40px;
    border-radius: 50%;
    background-color:#626060 ;
    border: 3px solid #fff;
    cursor: pointer;
    color: #f0f0f0;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 30px;
    font-weight: 800;
    transition: all .4s ease-in-out;
    @media (max-width:426px){
        right: 10px;
        z-index: 9999;



}
    &:hover{
        color: #ff5c00;
    border: 3px solid #ff5c00;

    }
}
.Card{
    position: relative;
    width: 100%;
    height: 100%;
    .heading{
        color: #ff5c00;
        text-align: center;
        cursor: default;
        text-shadow:0px 1px 2px #000000;
    }
    .flex{
        position: relative;
        display: flex;
        align-items: center;
        margin-top: 20px;
        justify-content: space-between;
        @media (max-width:426px){
            flex-wrap:wrap-reverse;
        }

    .leftSide{
        width: 50%;
        padding-left: 30px;
        @media (max-width:425px){
        width: 100%;
        margin:0;

        }
    
    .list{
        display: flex;
        width: 100%;
        height: 40px;
        align-items: center;
        gap: 10px;
        color: #f0f0f0;
        @media (max-width:425px){
        font-size: .8rem;
        height: 30px;

        }
    
        .listTite{
            /* font-weight: 300; */
            color: #ff5c00;
            text-shadow:0px 1px 1px #000000;
            @media (max-width:425px){
     font-size: .8rem;

    }
    

        }

    }
}
.rightSide{
    width: 50%;
    padding-right: 30px;
    @media (max-width:425px){
        width: 100%;
        margin:0 10px;

    }
    img{
        width: 350px;
        height: 200px;
        border-radius: 10px;
        float: right;
        @media (max-width:425px){
        width: 100%;
        height: 100px;
    }
    }
}
}
.discription{
    width: 100%;
    padding: 10px 20px;
    margin: 20px 0;
    p{
        color: #111;
    }
    }
 }

`;
