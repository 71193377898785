import React from 'react'
import { styled } from 'styled-components'
import TypeWriter from './TyperText'
import { SkillData } from '../../Skills'
const AnimateSkillBox = () => {
    return (
        <Container>
            <h4>My Skill <span><TypeWriter Data={SkillData} /></span> </h4>

        </Container>
    )
}

export default AnimateSkillBox

const Container = styled.div`
    width: 100%;
    height: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    h4{
        font-size: 2rem;
        color: #fff;

   
    span{
        margin-left: 5px;
        color: #ff5c00;
    }
}
`