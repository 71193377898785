import React, { useEffect, useState } from 'react'
import { styled } from 'styled-components'
import { BiSolidUpArrowAlt } from 'react-icons/bi'

const TopBtn = () => {
    const [screen, setScreen] = useState(false);
    const listenToScroll = () => {
        const hideTop = 250;
        const winScroll = document.body.scrollTop || document.documentElement.scrollTop;
        if (winScroll > hideTop) {
            setScreen(true);

        } else {
            setScreen(false);

        }
    }


    useEffect(() => {
        window.addEventListener('scroll', listenToScroll);
    }, [])

    const GoTop = () => {
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });

    }

    return (
        <>
            {
                screen && (<Container onClick={GoTop}><BiSolidUpArrowAlt /></Container>)
            }
        </>



    )
}

export default TopBtn

const Container = styled.button`
 position: fixed;
 right:20px;
 bottom: 20px;
 width: 50px;
 height: 50px;
 border-radius: 50%;
 font-size: 30px;
 display: flex;
 align-items: center;
 justify-content: center;
 border: none;
 background-color: #ff5c00;
 outline:2px solid #fff;
 color: #fff;
 font-weight: bold;
 transition: all .5s;
 cursor: pointer;
 z-index: 999999;
 animation:animate2 linear 4s infinite alternate-reverse ;
 @keyframes animate2 {
    100%{
         
        outline:2px solid #000;


    }
    0%{
        outline:2px solid #fff;

    }
}
 @media (max-width:426px) {
    width: 30px;
    height: 30px;
    bottom: 70px;

    font-size: 20px;
    
 }
&:hover{
    color: #ff5c00;
    background-color: #fff;
    outline:2px solid #ff5c00;
}
svg{
    animation:animate linear 2s infinite alternate-reverse ;
@keyframes animate {
    100%{
          position: relative;
          top: 2px;
          color: black;
    }
    0%{
          position: relative;
          bottom: 2px;
    }
}
    
}
`;